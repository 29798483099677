import React, { useState, useEffect, useMemo } from "react";
import {
  DxcHeading,
  DxcBox,
  DxcTextInput,
  DxcLink,
  DxcSpinner,
  DxcAlert,
  DxcParagraph,
  DxcFlex,
  DxcInset,
  DxcTypography,
} from "@dxc-technology/halstack-react";
import styled from "styled-components";
import arrow from "../images/arrowRight.svg";
import axios from "axios";
import { fakeServices } from "./fakeServices.js";
import { getSessionHeaders } from "../common/utils";

const searchIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 0 24 24"
      width="24"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
    </svg>
  );
};

const realmsServiceInfoToPrint = {
  description: "User profile management for your applications",
  label: "Realms Configuration",
  path: "",
  linkText: "Start configuring users",
  domain: "Platform",
};
const uxServiceInfoToPrint = {
  description: "Custom branding for your applications",
  label: "UX Configuration",
  path: "",
  linkText: "Start branding applications",
  domain: "Platform",
};
const getInfoToBePrinted = (service) => {
  if (service.description.indexOf("Realms") > -1) {
    return { ...realmsServiceInfoToPrint, path: service.path };
  }
  if (service.description.indexOf("UX") > -1) {
    return { ...uxServiceInfoToPrint, path: service.path };
  }
  return service;
};

const getFilteredGroupServices = (services, group, filter) => {
  return services.filter(
    (service) =>
      service.label.toLowerCase().includes(filter.toLowerCase()) &&
      service.domain === group
  );
};
const Console = ({ companyName }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [services, setServices] = useState([]);
  const [error, setError] = useState(null);
  const groupsArray = [...new Set(services.map((item) => item.domain))];
  const closeError = () => {
    setError(null);
  };
  const [filter, setFilter] = useState("");

  const getServicesByGroup = useMemo(() => {
    const groupsToBePainted = [];
    if (filter) {
      groupsArray.map((group) => {
        const servicesForGroup = getFilteredGroupServices(
          services,
          group,
          filter
        );
        return (
          group !== "Platform" &&
          groupsToBePainted.push({
            groupName: group,
            services: servicesForGroup.length > 0 ? servicesForGroup : [],
          })
        );
      });
    } else {
      groupsArray.map((group) => {
        return (
          group !== "Platform" &&
          groupsToBePainted.push({
            groupName: group,
            services: services.filter(
              (service) =>
                service.domain !== "Platform" && service.domain === group
            ),
          })
        );
      });
    }
    return groupsToBePainted;
  }, [services, filter, groupsArray]);

  const onChange = ({ value }) => {
    setFilter(value);
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      if (!window?.assureConfig?.dev) {
        const containerName = window?.assureConfig?.containerName;
        const sessionHeaders = await getSessionHeaders();
        axios({
          method: "get",
          url: `${window.assureConfig.platformconsoleApiBaseUrl}?containerName=${containerName}`,
          headers: {
            ...window.assureConfig.apiHeaders,
            ...sessionHeaders,
          },
        })
          .then((response) => {
            setServices(response?.data);
            setIsLoading(false);
          })
          .catch((error) => {
            setError(error);
            setIsLoading(false);
          });
      } else {
        setServices(fakeServices);
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);
  return (
    <>
      {isLoading && <DxcSpinner label="Loading" mode="overlay" />}
      {!isLoading && (
        <DxcInset bottom="5rem">
          <ErrorAlert>
            {error && (
              <DxcAlert
                type="error"
                mode="inline"
                onClose={closeError}
                inlineText={error.toJSON().message}
              />
            )}
          </ErrorAlert>
          <GreyDiv>
            <DxcFlex direction="column" alignItems="center">
              <Container>
                <DxcInset top="5rem" bottom="5rem" horizontal="5rem">
                  <DxcFlex direction="column" gap="4rem">
                    <TitleContainer>
                      <DxcHeading
                        level={1}
                        text={
                          (companyName &&
                            `${companyName} - Assure Platform Console`) ||
                          "Assure Platform Console"
                        }
                      />
                    </TitleContainer>
                    <DxcFlex gap="2rem">
                      {services.map((service) => {
                        let serviceToPrint = {};
                        serviceToPrint = getInfoToBePrinted(service);
                        return (
                          serviceToPrint.domain === "Platform" && (
                            <>
                              <ConfigContainer>
                                <DxcFlex
                                  direction="column"
                                  alignItems="flex-start"
                                  gap="0.25rem"
                                >
                                  <DxcParagraph>
                                    {serviceToPrint.description}
                                  </DxcParagraph>
                                  <DxcHeading
                                    level={2}
                                    text={serviceToPrint.label}
                                  />
                                  <DxcLink
                                    href={serviceToPrint.path}
                                    icon={arrow}
                                    iconPosition="after"
                                    underlined={false}
                                  >
                                    {serviceToPrint.linkText ||
                                      serviceToPrint.label}
                                  </DxcLink>
                                </DxcFlex>
                              </ConfigContainer>
                            </>
                          )
                        );
                      })}
                    </DxcFlex>
                  </DxcFlex>
                </DxcInset>
              </Container>
            </DxcFlex>
            <DxcFlex direction="column" alignItems="center">
              <ServicesContainer>
                <DxcInset top="2rem">
                  <DxcBox size="fillParent">
                    <DxcFlex direction="column" grow={1} shrink={1} basis="0%">
                      <DxcInset
                        top="1.5rem"
                        right="2rem"
                        left="2rem"
                        bottom="3rem"
                      >
                        <SearchContainer>
                          <DxcHeading level={3} text="All services" />
                          <DxcInset top="3rem" bottom="4rem">
                            <DxcTextInput
                              placeholder="Search a product from your solution..."
                              prefix={searchIcon()}
                              size="fillParent"
                              onChange={onChange}
                            />
                          </DxcInset>
                        </SearchContainer>
                        {(groupsArray.length === 0 && (
                          <NoItemsContainer>
                            <DxcFlex
                              alignItems="center"
                              justifyContent="center"
                            >
                              <DxcParagraph>
                                There are no services.
                              </DxcParagraph>
                            </DxcFlex>
                          </NoItemsContainer>
                        )) || (
                          <DxcFlex wrap="wrap">
                            {getServicesByGroup.map((group) => {
                              return (
                                <GroupContainer>
                                  <DxcInset right="2rem" vertical="1rem">
                                    <DxcFlex direction="column">
                                      <DxcFlex alignItems="center">
                                        <DxcInset bottom="1rem">
                                          <DxcHeading
                                            level={4}
                                            text={
                                              group.groupName || "Without group"
                                            }
                                          />
                                        </DxcInset>
                                      </DxcFlex>
                                      {(group.services.length > 0 &&
                                        group.services.map((service) => {
                                          return (
                                            <DxcInset bottom="0.5rem">
                                              <DxcLink
                                                href={service.path}
                                                underlined={false}
                                              >
                                                {service.label}
                                              </DxcLink>
                                            </DxcInset>
                                          );
                                        })) || (
                                        <DxcTypography
                                          fontSize="0.875rem"
                                          fontStyle="italic"
                                        >
                                          There are no services.
                                        </DxcTypography>
                                      )}
                                    </DxcFlex>
                                  </DxcInset>
                                </GroupContainer>
                              );
                            })}
                          </DxcFlex>
                        )}
                      </DxcInset>
                    </DxcFlex>
                  </DxcBox>
                </DxcInset>
              </ServicesContainer>
            </DxcFlex>
          </GreyDiv>
        </DxcInset>
      )}
    </>
  );
};
const GroupContainer = styled.div`
  min-width: 200px;
  min-height: 170px;
`;
const NoItemsContainer = styled.div`
  background: #eeeeee 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  height: 52px;
  color: #666666;
`;
const ErrorAlert = styled.div`
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: flex-end;
  top: 100px;
  right: 48px;
`;

const ConfigContainer = styled.div`
  width: 30%;
`;
const GreyDiv = styled.div`
  > div:first-child {
    background-color: #f2f2f2;
    z-index: -1;
  }
`;
const Container = styled.div`
  width: 85%;
`;
const SearchContainer = styled.div`
  width: 100%;
`;
const ServicesContainer = styled.div`
  background-color: white;
  width: 80%;
`;
const TitleContainer = styled.div`
  height: 65px;
`;
export default Console;
